














































































import { Component, Vue } from 'vue-property-decorator'
import { callAppFunc_navigateFeedBack, appFunc_callExit } from '@/utils/bridge'
@Component({
  name: 'Feedback'
})
export default class Feedback extends Vue {
  [x: string]: any
  //   tab栏数组
  collapseBtnList: any = [
    {
      id: 1,
      ch: '如何发起单聊/ 群聊/ 快速聊天？',
      desc:
        '您好，发起聊天共有两种方法，您可优先选择以下其一方式，作为聊天入口。',
      children: [
        {
          id: 1,
          title:
            '（1）打开软件——点击首页右下角蓝色浮标——点击找人单聊/ 进入群聊广场——下滑至联系人/可能认识的人页面，选择您想要聊天的对象。'
        },
        {
          id: 2,
          title:
            '（2）打开软件——点击右上角放大镜——搜索或选择”allo认证/ ”有号码“的用户，选择您想要聊天的对象。'
        }
      ]
    },
    {
      id: 2,
      ch: '如何使用拨号功能？',
      children: [
        {
          id: 1,
          title: '（1）输入数字拨号：',
          content:
            '打开软件——选择下工具栏“拨号”页面——点击右下角“键盘浮标”——可直接输入数字拨号。'
        },
        {
          id: 2,
          title: '（2）查看通话记录：',
          content:
            '打开软件——选择下工具栏“拨号”页面——点击右上方“+”——可查看相关来电显示。'
        },
        {
          id: 3,
          title: '（3）查找号码：',
          content:
            '打开软件——点击“联系人”或“拨号”页面——顶端下拉页面——输入号码或名称，查找号码。'
        }
      ]
    },
    {
      id: 3,
      ch: '如何申请实名认证？',
      desc:
        '打开软件——点击“我的“页面”——点击头像下方“申请认证”——填写相关资料上传后——系统将通知您认证结果。'
    },
    {
      id: 4,
      ch: '怎么样设置默认应用？',
      desc:
        '点击拨号页上方橘色按钮后开启悬浮窗权限后，在默认应用位置选择allo作为默认电话；',
      tip:
        '注意：OPPOa系列机型：开启悬浮窗权限后跳转至 系统默认应用页面选择“电话应用”，在“电话应用”页面内选择 allo'
    },
    {
      id: 5,
      ch: '为什么设置后没有声音？',
      desc:
        '检查您SIM卡是否在卡槽2位置，由于当前手机厂商原因暂不支持卡槽2来电铃声；'
    },
    {
      id: 6,
      ch: '为什么无法设置动态壁纸为锁屏显示？',
      desc: '锁屏设置机制由您当前的手机厂商性质决定，部分手机可以设置动态锁屏；'
    },
    {
      id: 7,
      ch: '为什么我的昵称/头像/视频/铃声/图片审核没通过？',
      desc:
        '审核机制大部分为自动审核，若审核不通过，请检查视频图片或铃声内容是否存在违规画面等，若有疑问，可通过意见反馈给我们或直接咨询客服'
    },
    {
      id: 8,
      ch: '退款规则？',
      desc:
        '仅限于已购买违禁内容的素材可退款，其他情况不予退款，且付费超过一年不予退款'
    },
    {
      id: 9,
      ch: '实名认证有什么意义/好处？',
      desc:
        '认证是对用户资料真实性进行的一种验证审核，通过认证意味着该用户的相关资质是合格的。可以提供特殊标识、认证页面独立展示，全平台用户搜索，新功能上线后也有更多的曝光。'
    }
    // {
    //   id: 10,
    //   ch: '实名认证后，能否宣传自己的品牌或者店铺？',
    //   desc:
    //     '可以在骚挠的范围外适当宣传，随后的版本我们会引进一些新的功能，比如专门的频道，动态功能帮助您更好的宣传。'
    // },
    // {
    //   id: 11,
    //   ch: '附近的人功能，为什么消失了？',
    //   desc:
    //     '您好，由于用户反馈被骚扰过多，我们暂时下线了该功能，并以你可能认识的人代替，后续版本优化后，再恢复使用。'
    // }
  ]

  //ios问题数组
  IosList: any = [
    {
      id: 1,
      ch: '为什么我的昵称/头像/视频/铃声/图片审核没通过？',
      desc:
        '审核机制大部分为自动审核，若审核不通过，请检查视频图片或铃声内容是否存在违规画面等，若有疑问，可通过意见反馈给我们或直接咨询客服'
    },
    {
      id: 2,
      ch: '账号被封无法登录？',
      desc:
        '若您的allo账号不能登录/被限制聊天，是因为您违法了“用户服务条款”导致的，若有疑问，请咨询客服'
    },
    {
      id: 3,
      ch: '如何修改个人昵称？',
      desc:
        '若需要修改allo个人昵称，操作方法：【我的】-【头像】-【昵称】即可修改昵称；'
    },
    {
      id: 4,
      ch: '被限制聊天了怎么办？',
      desc: '无法发送消息可能是被禁言或者被拉黑等情况；'
    }
  ]
  newList: any = [
    {
      id: 1,
      ch: '如何设置备注？',
      desc:
        '您可通过对方用户的个人资料页 -> 右上角更多中的“设置备注名”设置；或通过与对方用户私信 -> 右上角更多中的“设置备注名”设置'
    },
    {
      id: 2,
      ch: '如何设置“拍一拍”？',
      desc:
        '若有用户在私信中“拍一拍”您，则您可通过此条“拍一拍”消息设置您自己的“拍一拍”；或通过我的 -> 设置 -> 消息设置中的“拍一拍”设置您自己的拍一拍'
    },
    {
      id: 3,
      ch: '如何设置消息通知？',
      desc: '您可通过我的 -> 设置 -> 消息设置中进行消息通知相关的设置'
    },
    {
      id: 4,
      ch: '如何修改密码？',
      desc: '您可通过我的 -> 设置 -> 修改密码中进行密码修改'
    },
    {
      id: 5,
      ch: '如何修改邮箱？',
      desc: '您可通过我的 -> 设置 -> 修改邮箱中进行邮箱修改'
    },
    {
      id: 6,
      ch: '为什么我不能发送消息了？',
      desc:
        '您可能存在违反用户服务协议中相关规定的行为且被平台禁言一定时间，如有异议，您可通过我的 -> 帮助与反馈 -> 意见反馈中联系我们'
    },
    {
      id: 7,
      ch: '为什么我的账号被封禁了？',
      desc:
        '您可能存在违反用户服务协议中相关规定的行为且被平台封号，如有异议，您可通过我的 -> 帮助与反馈 -> 意见反馈中联系我们'
    },
    {
      id: 8,
      ch: '账号被封禁后怎么解除？',
      desc: '您可通过我的 -> 帮助与反馈 -> 意见反馈中联系我们'
    },
    {
      id: 9,
      ch: '为什么我发布的动态不见了？',
      desc:
        '您发布的动态可能存在违规内容，包括但不限于暴力、血腥、恐怖主义、涉黄等，如有异议，您可通过我的 -> 帮助与反馈 -> 意见反馈中联系我们'
    },
    {
      id: 10,
      ch: '为什么我发布的评论不见了？',
      desc:
        '您发布的评论可能存在违规内容，包括但不限于暴力、血腥、恐怖主义、涉黄等，如有异议，您可通过我的 -> 帮助与反馈 -> 意见反馈中联系我们'
    }
  ]
  choose: any = '' // 点击的collapse Item编号
  setChooseCollapse(item: { id: any }) {
    const chooseCurrent = item.id
    if (this.choose == chooseCurrent) {
      this.choose = ''
      return
    }
    this.choose = chooseCurrent
  }
  // 当前模式
  get mode() {
    const data = this.$route.query.backgroundMode as string
    return data || 'light'
  }

  get isShow() {
    if (/(iPhone|iOS)/i.test(navigator.userAgent)) {
      return true
    } else if (/(Android)/i.test(navigator.userAgent)) {
      return false
    } else {
      return false
    }
  }

  get List() {
    if (/(iPhone|iOS)/i.test(navigator.userAgent)) {
      return this.IosList
    } else if (/(Android)/i.test(navigator.userAgent)) {
      return this.collapseBtnList
    } else {
      return false
    }
  }

  // 当前语言
  get language() {
    return this.$i18n.locale
  }
  // 跳转至问题反馈页
  handelNavigateFeedBack() {
    if (/(iPhone|iOS)/i.test(navigator.userAgent)) {
      this.$router.push({
        path: '/problem',
        query: {
          language: this.language
        }
      })
    } else if (/(Android)/i.test(navigator.userAgent)) {
      callAppFunc_navigateFeedBack()
    } else {
      return false
    }
  }
  mounted() {
    //
  }
  // 切换语言
  getLanguage(language: string) {
    this.$i18n.locale = language || 'ug'
  }

  handelback() {
    appFunc_callExit()
  }
}
